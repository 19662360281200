import { Swiper, SwiperSlide } from 'swiper/react'
import s from '../../Home.module.css'
import cn from 'classnames'
import Arrow from '../Arrow'
import { refLink } from '../../utils'
import { Link, Picture } from '@components/ui'
import useProduct from '@shopify/product/use-product'
import { useCommerce } from '@commerce'
import { useCallback, useState } from 'react'
import { Star } from '@components/icons/home'
import { NextArrow, PrevArrow } from '../Components/SwiperArrow'

/**
 * Renders a section of reviews.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data.
 * @param {string} props.locale - The locale.
 * @param {Object} props.shopCommon - The common shop data.
 * @param {Object} props.releatedProduct - The related product data.
 * @returns {JSX.Element} The rendered component.
 */
const Reviews = ({ section, shopCommon, relatedProducts }) => {
  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)
  const [swiper, setSwiper] = useState()

  const handleProgress = (swiper) => {
    setStart(swiper.isBeginning)
    setEnd(swiper.isEnd)
  }

  const settings = {
    spaceBetween: 8,
    slidesPerView: 'auto' || section.slidesPerView,
    breakpoints: section.breakpoints || {
      1024: {
        allowTouchMove: false,
      },
    },
    onProgress: handleProgress,
    onSwiper: setSwiper,
  }
  const { data } = useProduct({
    handles: section.lists?.map((item) => item.handle).join(','),
  })

  const { shop } = useCommerce()

  const getProduct = useCallback(
    (item) => {
      const product = relatedProducts.find(
        (product) =>
          product.origin.handle === item.handle &&
          product.origin.sku === item.sku
      )
      let _product
      if (product && product.result) {
        _product = product.result
        _product.variant = product.result.variants?.find(
          (variant) => variant.sku === item.sku
        )
      } else {
        data?.products?.forEach((product) => {
          if (product.handle === item.handle) {
            product.variants?.forEach((variant) => {
              if (variant.sku === item.sku) {
                _product = product
                _product.variant = variant
              }
            })
          }
        })
      }
      return _product
    },
    [data, relatedProducts]
  )
  return (
    <div className={cn('layer overflow-hidden', s.reviews)}>
      <div className="content">
        <div className="py-5 min-l:py-10 min-xl:py-[60px]">
          <h2
            className={s.title}
            dangerouslySetInnerHTML={{ __html: section.title }}
          ></h2>
          {section.lists && (
            <div className="">
              <Swiper
                {...settings}
                className="mt-6 !overflow-visible min-l:mt-8 min-l:!pb-6 min-xl:mt-12"
              >
                {section.lists?.map((item, index) => {
                  const product = getProduct(item)
                  if (!product || !product.variant) return null
                  const image =
                    item.image ||
                    product.variant?.image?.url ||
                    product.images?.[0]?.url
                  return (
                    <SwiperSlide
                      key={index}
                      className={cn(
                        s.reviewSlide,
                        '!w-[298px] min-l:!w-[334px] min-xxl:!w-[454px]'
                      )}
                    >
                      <div
                        className={cn(
                          'box-border h-full overflow-hidden rounded-xl bg-white px-4 pb-32 pt-8 min-l:flex min-l:flex-col min-l:justify-between min-l:gap-3 min-l:px-6 min-xl:min-h-[388px] min-xl:pb-0 min-xl:pt-8'
                          // s.card
                        )}
                      >
                        <div>
                          <div className="mb-4 flex items-center gap-3 min-l:mb-8 min-l:gap-4 min-xl:gap-5">
                            {/* <Picture source={item.icon} className="w-12" /> */}
                            <div className="flex h-[64px] w-[64px] items-center justify-center rounded-full bg-[#B3ECFB] text-2xl font-semibold text-brand-color min-xl:h-[72px] min-xl:w-[72px]  min-xl:text-2xl">
                              {item.name.substring(0, 2).toUpperCase()}
                            </div>
                            {/* <h4
                          className="mt-4 text-lg font-semibold"
                          dangerouslySetInnerHTML={{ __html: item.name }}
                        ></h4> */}
                            <div>
                              <p
                                className="mt-1 text-[20px] font-semibold !leading-[1.2] text-[#333] min-l:text-sm min-xl:text-[20px]"
                                dangerouslySetInnerHTML={{ __html: item.name }}
                              ></p>
                              <div className="mt-[6px] min-xl:mt-2">
                                {!!item.stars && (
                                  <div className="flex items-center gap-1">
                                    {Array.from({ length: item.stars }).map(
                                      (item, index) => (
                                        <Star
                                          className="text-[#F1BB3F]"
                                          key={index}
                                        />
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <Quote className="text-[#B3ECFB]" /> */}
                          <div
                            className="mt-6 pb-6 text-base font-medium leading-[1.18] text-[#333] opacity-75 min-l:text-xs min-xl:text-base"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          ></div>
                        </div>
                        <div className="">
                          <div className="absolute bottom-0 left-0 w-full px-4 min-l:px-6 min-xl:relative min-xl:mt-3 min-xl:px-0">
                            <div className="flex items-center gap-3 border-t border-[#e4e4e4] py-3">
                              <Link
                                href={refLink(
                                  item.learn_more,
                                  section?.title,
                                  index + 1
                                )}
                              >
                                <Picture
                                  source={image}
                                  className="h-[82px] w-[82px]"
                                />
                              </Link>
                              <div className="text-sm font-semibold text-[#666] min-xl:text-base">
                                <Link
                                  href={refLink(
                                    item.learn_more,
                                    section?.title,
                                    index + 1
                                  )}
                                  className="nounderline"
                                >
                                  <p
                                    className="mb-2 line-clamp-2 text-sm !leading-[1.2] text-[#333] min-l:text-xs min-xl:text-base"
                                    title={product.title || item.title}
                                  >
                                    {product.title || item.title}
                                  </p>
                                </Link>
                                <Link
                                  href={refLink(
                                    item.learn_more,
                                    section?.title,
                                    index + 1
                                  )}
                                  className="flex items-center gap-[2px] text-sm font-semibold text-brand-color min-l:text-xs min-xl:text-base"
                                >
                                  <span>
                                    {section.learn_more ||
                                      shopCommon.learn_more}
                                  </span>
                                  <Arrow />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })}
                <PrevArrow
                  swiper={swiper}
                  start={start}
                  end={end}
                  title={section.title}
                />
                <NextArrow
                  swiper={swiper}
                  start={start}
                  end={end}
                  title={section.title}
                />
              </Swiper>
              {/* <div
                className={cn(
                  'hidden items-center justify-end gap-4 min-l:flex',
                  {
                    '!hidden': start && end,
                  }
                )}
              >
                <PrevArrow />
                <NextArrow />
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Reviews
